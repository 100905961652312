import mqtt from 'mqtt'

let mqttClient = null

// Initialize MQTT Client
export const initializeMqtt = (brokerUrl, options) => {
  return new Promise((resolve, reject) => {
    mqttClient = mqtt.connect(brokerUrl, options)

    mqttClient.on('connect', () => {
      console.log('MQTT connected')
      resolve(mqttClient) // Resolve the promise when connected
    })

    mqttClient.on('error', (err) => {
      console.error('MQTT error:', err)
      resolve() // Reject the promise if there's an error
    })

    mqttClient.on('close', () => {
      resolve()
    })

    mqttClient.on('offline', (offline) => {
      resolve()
    })

    mqttClient.on('reconnect', (reconnect) => {
      resolve()
    })
  })
}

// Access MQTT Client
export const getMqttClient = () => {
  if (!mqttClient) {
    throw new Error('MQTT is not initialized. Call initializeMqtt first.')
  }
  return mqttClient
}

export const reconnectMqttClient = () => {
  if (mqttClient) {
    mqttClient.reconnect()
  }
}

// Disconnect MQTT Client
export const disconnectMqtt = () => {
  if (mqttClient) {
    mqttClient.end()
    mqttClient = null
    console.log('MQTT disconnected')
  }
}
