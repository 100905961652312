import { getField, updateField } from 'vuex-map-fields'
import _ from 'lodash-core'
import moment from 'moment'
import requests from '../requests'
import {
  ROOMS_URL,
  SEND_MESSAGE_URL,
  INTEGRATION_URL,
} from '~/assets/variables/endpoints'
import {
  getLatestRoomCursor,
  updateRoomPooling,
} from '~/utils/messaging/conversation'
import { timeDifference, calculateToMinute } from '~/utils/room'

export const state = () => ({
  roomDetail: null,
  roomLists: [],
  searchRoomLists: {
    contact: [],
    conversation: [],
    type: 'contact',
  },
  isFiltering: false,
  isLatest: 0,
  roomFormatted: [],
  roomTypingBanks: [],
  receivingTypingNotification: true,
  conversationMode: 'normal',
  roomCheckList: [],
  tagLists: [
    {
      id: 0,
      name: 'Prospect',
    },
    {
      id: 1,
      name: 'Whatsapp',
    },
    {
      id: 2,
      name: 'Complain',
    },
    {
      id: 3,
      name: 'Loyal',
    },
    {
      id: 4,
      name: 'Follow Up',
    },
    {
      id: 5,
      name: 'Order',
    },
    {
      id: 6,
      name: 'Cancel',
    },
    {
      id: 7,
      name: 'Delivered',
    },
  ],
  channelLists: [],
  contentLoading: {
    content: true,
  },
  info: {
    unassigned: 0,
    unread: 0,
  },
  roomFilter: {
    conversation: '',
  },
  filterParams: {
    sessions: [],
    tags: [],
    user_ids: [],
    channels: [],
    untagged: null,
    response_status: [],
  },
  expiredRoomCounts: {
    status: null,
    data: {},
  },
  noRoom: false,
  isWaCloud: false,
  inboxTabs: 'messages',
  unreadCount: {
    messages: null,
    comments: null,
  },
  surveyFlow: 'auto', // default auto | manual
  inboxPooling: {
    checkpoint: null,
    triggerAt: {},
    defaultConfig: null,
    cursor: null,
  },
  enable_inbox_sla: false,
  enable_get_division_chat: false,
  unassignedCount: 0,
})

export const getters = {
  getField,
}

export const actions = {
  getRoomLists({ commit, state, dispatch }, params) {
    const isLongPooling = params.is_pooling
    const isFiltering =
      (state.filterParams.tags && state.filterParams.tags.length) ||
      (typeof state.filterParams.untagged === 'boolean' &&
        state.filterParams.untagged) ||
      (state.filterParams.sessions && state.filterParams.sessions.length) ||
      (state.filterParams.channels && state.filterParams.channels.length) ||
      (state.filterParams.response_status &&
        state.filterParams.response_status.length)
    commit('UPDATE_IS_FILTERING', isFiltering)
    let channels = state.filterParams.channels

    // handle filter if there is at least one session checked, only show "wa" or "wa_cloud" or meta product
    if (state.filterParams.sessions.length > 0) {
      if (
        state.filterParams.channels.includes('wa') ||
        state.filterParams.channels.includes('wa_cloud') ||
        state.filterParams.channels.includes('fb') ||
        state.filterParams.channels.includes('ig')
      ) {
        params.parameter.limit = 10
        channels = state.filterParams.channels.filter((ch) => {
          return (
            ch.includes('wa') ||
            ch.includes('wa_cloud') ||
            ch.includes('fb') ||
            ch.includes('ig')
          )
        })
      } else {
        params.parameter.limit = 0
        channels = ['wa', 'wa_cloud', 'fb', 'ig']
      }

      // when there is no channel but ticked on of the expiring type
      if (state.filterParams.channels.length < 1) {
        channels = ['wa', 'wa_cloud', 'fb', 'ig']
        params.parameter.limit = 10
      }
    } else {
      params.parameter.limit = 10
      channels = state.filterParams.channels
    }

    if (isLongPooling) {
      params.parameter.offset = 0
      params.parameter.limit = 10
      // @TODO need revisit later
      // params.parameter.cursor = state.inboxPooling.cursor
      params.parameter.cursor = getLatestRoomCursor(state.roomLists)
      params.parameter.cursor_direction = 'after'
    }

    return new Promise((resolve) => {
      requests.whatsapp
        .getService(
          `${ROOMS_URL}`,
          {
            ...params.parameter,
            ...state.filterParams,
            channels,
          },
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          (res) => {
            if (isLongPooling) {
              // start long pooling room map
              commit('UPDATE_ROOMS_BY_POOLING', res.data)
            } else {
              commit('layouts/SET_META', res.meta, { root: true })
              commit('UPDATE_ROOMS', res.data)
              if (params.parameter.offset === 1) {
                commit(
                  'SET_INBOX_POOLING_CURSOR',
                  res.meta.pagination.cursor.next
                )
              }
            }
            resolve({ status: true, total: res.data.length, data: res.data })
          },
          (err) => {
            if (!isLongPooling) {
              commit(
                'layouts/UPDATE_NOTIFICATION',
                {
                  display: true,
                  type: 'failed',
                  message: 'Error!',
                  item: err?.error?.messages[0] || '',
                  callback: {
                    text: 'OK',
                    method: 'closeNotification',
                  },
                },
                { root: true }
              )
            }
            resolve({ status: true, total: 0 })
          }
        )
    })
  },
  getSearchRoomLists({ commit, state }, params) {
    let searchUrl = ROOMS_URL
    if (state.searchRoomLists.type === 'conversation') {
      searchUrl = `${SEND_MESSAGE_URL}/search`
      params = {
        ...params,
        'room_type[]': params['type[]'],
      }
      delete params['type[]']
    }
    return new Promise((resolve) => {
      requests.whatsapp
        .getService(searchUrl, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            commit('layouts/SET_SEARCH_ROOM_META', res.meta, { root: true })
            commit('UPDATE_SEARCH_ROOMS', {
              data: res.data,
              input: params.query,
            })
            resolve({ status: true, total: res.data.length })
          },
          (err) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err.error.messages[0],
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            resolve({ status: true, total: 0 })
          }
        )
    })
  },
  getRoomDetail({ commit, state, dispatch }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(
          `${ROOMS_URL}/${params}`,
          {},
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          (res) => {
            commit('UPDATE_ROOM_DETAIL', res.data)
            resolve(res.data)
          },
          (err) => {
            reject(err)
          }
        )
    })
  },
  getRoomSpecificInfo({ commit, state }, params) {
    const inboxTabs = this.$router.currentRoute?.query?.type
    let models = null

    if (inboxTabs === 'comments') {
      models = 'comment'
    } else {
      models = null
    }

    if (this.$router.currentRoute.name === 'inbox') {
      commit(
        'layouts/UPDATE_CONTENTLOADING',
        { type: 'table', status: true },
        { root: true }
      )
      if (state.enable_get_division_chat) params.target = 'unassigned_division'
      requests.whatsapp
        .getService(
          `${ROOMS_URL}/specific/info`,
          { ...params, type: models },
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          (res) => {
            commit('UPDATE_UNASSIGNED_COUNT', res.data.info.total)
            commit(
              'layouts/UPDATE_CONTENTLOADING',
              { type: 'table', status: false },
              { root: true }
            )
          },
          (err) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err?.error?.messages[0] || '',
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            commit(
              'layouts/UPDATE_CONTENTLOADING',
              { type: 'table', status: false },
              { root: true }
            )
          }
        )
    }
  },
  getNewChatFromAgent({ commit, dispatch, state }, params) {
    return new Promise((resolve, reject) => {
      const url = state.enable_get_division_chat
        ? 'takeover_next'
        : 'auto_takeover'
      requests.whatsapp
        .postService(
          `${ROOMS_URL}/${url}`,
          {},
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          (res) => {
            resolve(true)
          },
          (err) => {
            if (err?.error?.messages[0] === 'All room already assigned') {
              dispatch(
                'inbox/getRoomSpecificInfo',
                { target: 'unassigned' },
                { root: true }
              )
            }

            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err?.error?.messages[0] || '',
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            reject(err)
          }
        )
    })
  },
  getExpiredRoomPerChannel({ commit, dispatch }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(
          `${ROOMS_URL}/list/expired`,
          {},
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          (res) => {
            commit('UPDATE_EXPIRED_ROOM_PER_CHANNEL', res)
            resolve(res.data.data)
          },
          (err) => {
            commit('UPDATE_EXPIRED_ROOM_PER_CHANNEL', {
              status: err.status,
              data: { data: {} },
            })
            reject(err)
          }
        )
    })
  },
  resolveAllExpireRoom({ commit, dispatch }, params) {
    if (!params?.is_new_survey_flow) {
      delete params?.is_new_survey_flow
      delete params?.is_manual_survey
      delete params?.is_sent_survey
    }
    delete params?.is_new_survey_flow
    delete params?.is_manual_survey

    return new Promise((resolve, reject) => {
      requests.whatsapp
        .putService(`${ROOMS_URL}/resolve_expired`, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            resolve(res.status)
          },
          (err) => {
            reject(err)
          }
        )
    })
  },
  updateDontAutoResolveRoom({ commit, state }, params) {
    commit('layouts/UPDATE_CONTENTLOADING', { status: true }, { root: true })
    requests.whatsapp
      .putService(`${ROOMS_URL}/${params.id}/auto_resolve`, params, {
        Authorization: this.$auth.getToken('hub'),
      })
      .subscribe(
        (res) => {
          commit(
            'layouts/UPDATE_CONTENTLOADING',
            { status: false },
            { root: true }
          )
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'success',
              message: 'Successfully Updated!',
              item: '',
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
        },
        (err) => {
          commit(
            'layouts/UPDATE_CONTENTLOADING',
            { status: false },
            { root: true }
          )
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: err.error.messages[0],
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
        }
      )
  },
  async updateTypingOnRDB({ commit, state }, params) {
    await commit('UPDATE_TYPING_BANKS_STATUS', params)
    this.$fireDb
      .ref(`room/${this.$auth.user.organization_id}`)
      .set(state.roomTypingBanks)
      .then(() => {})
      .catch(() => {})
  },
  async deleteTypingOnRDB({ commit, state, dispatch }, params) {
    await commit('DELETE_TYPING_BANKS_STATUS', params)
    // if (state.roomTypingBanks[params.room_id].isTyping.length < 1 || state.roomTypingBanks[params.room_id].isTyping === false) {
    //   dispatch('removeCurrentRoomFromRDB', { room_id: params.room_id })
    // }
    this.$fireDb
      .ref(`room/${this.$auth.user.organization_id}`)
      .set(state.roomTypingBanks)
      .catch(() => {})
  },
  async disconnectTypingHandler({ commit, state, dispatch }, params) {
    await commit('REMOVE_USER_TYPING_ON_BANKS', params)
    // if (state.roomTypingBanks[params.room_id].isTyping.length < 1 || state.roomTypingBanks[params.room_id].isTyping === false)
    //   dispatch('removeCurrentRoomFromRDB', { room_id: params.room_id })
    this.$fireDb
      .ref(`room/${this.$auth.user.organization_id}`)
      .onDisconnect('value')
      .set(state.roomTypingBanks)
  },
  removeCurrentRoomFromRDB({ commit, state }, params) {
    this.$fireDb
      .ref(`room/${this.$auth.user.organization_id}/${params.room_id}`)
      .remove()
  },
  liftUpRoomById({ commit, state }, params) {
    const roomIdx = _.findIndex(state.roomLists, { id: params })
    const room = _.find(state.roomLists, { id: params })

    if (roomIdx && room) {
      commit('UPDATE_LIFT_UP_ROOM', {
        index: roomIdx,
        room,
      })
    }
  },
  getAllEmailRecipients({ commit }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(
          `${ROOMS_URL}/${params.id}/mail_cc`,
          {},
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          (res) => {
            resolve(res.data)
          },
          (err) => {
            reject(err)
          }
        )
    })
  },
  getIntWaCloudOrWaOnPremise({ state, commit }, params) {
    requests.whatsapp
      .getService(INTEGRATION_URL, params, {
        Authorization: this.$auth.getToken('hub'),
      })
      .subscribe(
        (res) => {
          commit('UPDATE_IS_WA_CLOUD', res.data)
        },
        (err) => {
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: err.error.messages[0],
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
        }
      )
  },
  getUnreadCount({ state, commit }, params) {
    return new Promise((resolve, reject) => {
      const getUnreadCount = (type) => {
        type = type === 'comments' ? 'comment' : 'customer_service'
        const payload = {
          target: 'unread_count',
          type,
        }
        requests.whatsapp
          .getService(`${ROOMS_URL}/specific/info`, payload, {
            Authorization: this.$auth.getToken('hub'),
          })
          .subscribe(
            (res) => {
              commit('SET_UNREAD_COUNT', { data: res.data, type })
              resolve(res)
            },
            (err) => {
              reject(err)
            }
          )
      }
      getUnreadCount('comments')
      getUnreadCount('customer_service')
    })
  },
  getKMSArticleData({ state, commit }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(
          `${ROOMS_URL}/users/me`,
          {},
          {
            Authorization: this.$auth.getToken('hub'),
          }
        )
        .subscribe(
          (res) => {
            res.data = [
              {
                id: 1,
                date: '2024-01-24T02:29:51',
                date_gmt: '2024-01-24T02:29:51',
                guid: {
                  rendered: 'https://knowledge.qontak.net/?p=1',
                },
                modified: '2024-01-24T02:29:51',
                modified_gmt: '2024-01-24T02:29:51',
                slug: 'hello-world',
                status: 'publish',
                type: 'post',
                link: 'https://knowledge.qontak.net/2024/01/24/hello-world/',
                title: {
                  rendered: 'Hello world!',
                },
                content: {
                  rendered:
                    '<p>Welcome to WordPress. This is your first post. Edit or delete it, then start writing!</p>\n',
                  protected: false,
                },
                excerpt: {
                  rendered:
                    '<p>Welcome to WordPress. This is your first post. Edit or delete it, then start writing!</p> \n',
                  protected: false,
                },
                author: 1,
                featured_media: 0,
                comment_status: 'open',
                ping_status: 'open',
                sticky: false,
                template: '',
                format: 'standard',
                meta: {
                  qon_creator_id: '',
                  qon_creator_name: '',
                  qon_creator_avatar: '',
                  footnotes: '',
                },
                categories: [1],
                tags: [],
                post_categories: [
                  {
                    term_id: 1,
                    name: 'Uncategorized',
                    link: 'https://knowledge.qontak.net/category/uncategorized/',
                  },
                ],
                post_tags: [],
                _links: {
                  self: [
                    {
                      href: 'https://knowledge.qontak.net/wp-json/wp/v2/posts/1',
                    },
                  ],
                  collection: [
                    {
                      href: 'https://knowledge.qontak.net/wp-json/wp/v2/posts',
                    },
                  ],
                  about: [
                    {
                      href: 'https://knowledge.qontak.net/wp-json/wp/v2/types/post',
                    },
                  ],
                  author: [
                    {
                      embeddable: true,
                      href: 'https://knowledge.qontak.net/wp-json/wp/v2/users/1',
                    },
                  ],
                  replies: [
                    {
                      embeddable: true,
                      href: 'https://knowledge.qontak.net/wp-json/wp/v2/comments?post=1',
                    },
                  ],
                  'version-history': [
                    {
                      count: 0,
                      href: 'https://knowledge.qontak.net/wp-json/wp/v2/posts/1/revisions',
                    },
                  ],
                  'wp:attachment': [
                    {
                      href: 'https://knowledge.qontak.net/wp-json/wp/v2/media?parent=1',
                    },
                  ],
                  'wp:term': [
                    {
                      taxonomy: 'category',
                      embeddable: true,
                      href: 'https://knowledge.qontak.net/wp-json/wp/v2/categories?post=1',
                    },
                    {
                      taxonomy: 'post_tag',
                      embeddable: true,
                      href: 'https://knowledge.qontak.net/wp-json/wp/v2/tags?post=1',
                    },
                  ],
                  curies: [
                    {
                      name: 'wp',
                      href: 'https://api.w.org/{rel}',
                      templated: true,
                    },
                  ],
                },
              },
            ]
            resolve(res.data)
          },
          (err) => {
            reject(err)
          }
        )
    })
  },
  setInboxPoolingTrigger({ commit }, params) {
    return new Promise((resolve) => {
      commit('SET_INBOX_POOLING_TRIGGER', params)
      resolve(true)
    })
  },
  trackNotificationLog({ state }, params) {
    const headers = { authorization: this.$auth.getToken('hub') }
    const fcmToken = localStorage.getItem('subscribe_token') || null
    const parameter = {
      token: fcmToken,
    }
    this.$axios.post(
      `${process.env.HUB_SERVICE_URL}/api/core/v1/messages/${params}/ack`,
      parameter,
      { headers }
    )
  },
  getRoomSLA({ state, commit }, params) {
    const flagIsOn = state.enable_inbox_sla
    const poolingIsOn = state.inboxPooling?.defaultConfig?.is_active?.rooms_sla
    if (flagIsOn && poolingIsOn) {
      requests.whatsapp
        .getService(
          `${ROOMS_URL}/sla`,
          { room_ids: params.ids },
          {
            Authorization: this.$auth.getToken('hub'),
          }
        )
        .subscribe((res) => {
          res.data.forEach((sla) => {
            commit('UPDATE_SLA_ON_ROOM', {
              value: sla,
            })
          })
        })
    }
  },
}

export const mutations = {
  updateField,
  UPDATE_LIFT_UP_ROOM(state, payload) {
    const flagNewRoomOrderingLogic = payload?.flagNewRoomOrderingLogic || false
    if (flagNewRoomOrderingLogic) {
      const lastMsg = payload?.room?.last_message?.text || ''
      const type = payload?.room?.last_message?.type || ''
      const searchSurvey = lastMsg.search('Survey score has been sent') !== -1
      const searchResolved =
        lastMsg.search('Conversation has been resolved') !== -1
      const searchMsgFailedToSend =
        lastMsg.search('Message failed to send with error') !== -1
      if (
        type === 'system' &&
        (searchSurvey || searchResolved || searchMsgFailedToSend)
      ) {
        const index = payload.index

        if (
          state.roomFilter.conversation === payload.room.status ||
          state.roomFilter.conversation === '' ||
          state.roomFilter.conversation === undefined
        ) {
          // Update data
          state.roomLists.splice(index, 1, payload.room)
        }
        return
      }
    }

    const index = payload.index
    // Check if room is exist previously and delete it
    state.roomLists.splice(index, 1)

    if (
      state.roomFilter.conversation === payload.room.status ||
      state.roomFilter.conversation === '' ||
      state.roomFilter.conversation === undefined
    ) {
      // Put data to the first index of room list
      state.roomLists.unshift(payload.room)
    }
  },
  UPDATE_ROOM_DETAIL(state, payload) {
    state.roomDetail = { ...state.roomDetail, ...payload }
  },
  UPDATE_ROOM(state, payload) {
    // Shortening variable
    const conv = payload.conversationInformation

    // Define current room list
    const currentRoomLists = state.roomLists

    // Define the index of sent room
    const index = _.findIndex(state.roomLists, { id: conv.id })

    if (index > -1) {
      // Updating variable if exist
      const customMsg = payload.customMsg || {
        type: currentRoomLists[index].last_message.type,
        text: currentRoomLists[index].last_message.text,
      }
      const unreadUpdate =
        payload.unreadUpdate || currentRoomLists[index].unread_count
      const participantType =
        typeof payload.participant_type === 'undefined'
          ? currentRoomLists[index].last_message.participant_type
          : payload.participant_type

      // A room can be created within below format
      const data = currentRoomLists[index]
      data.last_message.type = customMsg.type
      data.last_message.text = customMsg.text
      data.last_message.participant_type = participantType
      data.created_at = moment().toISOString()
      data.unread_count = unreadUpdate
      data.status = conv.status

      const isResolveNotif = payload?.customMsg?.text?.search('resolved')
      if (isResolveNotif) return

      state.roomLists.splice(index, 1)
      state.roomLists.unshift(data)
    } else {
      conv.last_message.type = payload.type
      conv.last_message.text = payload.text
      conv.last_message_at = moment().toISOString()
      conv.unread_count = 0

      state.roomLists.unshift(conv)
    }
  },
  UPDATE_UNSHIFT_ROOM(state, payload) {
    const isInAllStatus = !state.roomFilter.conversation
    const isIncomingSameWithCurrStatus =
      state.roomFilter.conversation === payload.room.status

    if (!state.isFiltering && (isInAllStatus || isIncomingSameWithCurrStatus)) {
      state.roomLists.unshift(payload.room)
    }
  },
  UPDATE_ASSIGNATION_ROOM(state, payload) {
    const currentRoomLists = state.roomLists
    const index = _.findIndex(currentRoomLists, { id: payload.room })
    if (index > 1) {
      currentRoomLists[index].assigned_to = payload.sender.id
      currentRoomLists[index].status = 'assigned'
      state.roomLists = currentRoomLists
    }
  },
  UPDATE_CONTENTLOADING(state, payload) {
    state.contentLoading.content = payload
  },
  READ_ROOM(state, payload) {
    const currentRoomLists = state.roomLists
    const index = _.findIndex(currentRoomLists, { id: payload })
    if (index > -1) {
      currentRoomLists[index].unread_count = 0
      state.roomLists = currentRoomLists
    }
  },
  SET_ROOMS(state, payload) {
    state.roomLists = payload
  },
  SET_SEARCH_ROOMS(state, payload) {
    state.searchRoomLists.contact = state.searchRoomLists.conversation = payload
  },
  UPDATE_ROOMS(state, payload) {
    const merged = [...state.roomLists, ...payload]

    const unique = [...new Map(merged.map((room) => [room.id, room])).values()]

    state.roomLists = _.orderBy(unique, 'last_activity_at', 'desc')
  },
  UPDATE_SEARCH_ROOMS(state, payload) {
    let data = []
    if (state.searchRoomLists.type === 'conversation') {
      payload.data.forEach((el) => {
        const text = el.text?.includes(payload.input)
          ? `...${el.text.substring(el.text.indexOf(payload.input))}`
          : el.text
        if (el.room) {
          el.room.last_message = {
            text,
            type: el.type,
            participant_type: el.participant_type,
          }
        } else {
          return
        }
        data.push(el.room)
      })
    } else data = payload.data

    payload.data = data

    const merged = [
      ...state.searchRoomLists[state.searchRoomLists.type],
      ...payload.data,
    ]

    const unique = [...new Map(merged.map((room) => [room.id, room])).values()]

    state.searchRoomLists[state.searchRoomLists.type] =
      state.searchRoomLists.type === 'conversation'
        ? _.orderBy(merged, 'last_activity_at', 'desc')
        : _.orderBy(unique, 'last_activity_at', 'desc')
  },
  UPDATE_ROOM_INFO(state, payload) {
    state.info[payload.target] = payload.info.total
  },
  REMOVE_ROOM(state, payload) {
    if (!payload.index && payload.index !== 0) {
      if (state.roomFilter.conversation) {
        const index = _.findIndex(state.roomLists, { id: payload.id })
        state.roomLists.splice(index, 1)
      }
    } else state.roomLists.splice(payload.index, 1)
  },
  REMOVE_ROOM_EXCEPT_ON_SAME_FILTER(state, payload) {
    if (state.roomFilter.conversation) {
      if (state.roomFilter.conversation !== payload.filter) {
        const index = _.findIndex(state.roomLists, { id: payload.id })
        if (index > -1) state.roomLists.splice(index, 1)
      }
    }
  },
  UPDATE_UNASSIGNED_CHAT(state, payload) {
    switch (payload) {
      case 'add':
        state.info.unassigned++
        break
      case 'remove':
        state.info.unassigned--
        break
    }
  },
  UPDATE_EXACT_UNASSIGNED_CHAT(state, payload) {
    state.info.unassigned = payload
  },
  UPDATE_ROOM_TYPING_BANKS(state, payload) {
    state.roomTypingBanks = payload
  },
  UPDATE_TYPING_BANKS_STATUS(state, payload) {
    let data = []
    if (!state.roomTypingBanks[payload.room_id]) {
      data = []
    } else {
      data = state.roomTypingBanks[payload.room_id].isTyping
        ? state.roomTypingBanks[payload.room_id].isTyping
        : []
    }
    if (state.roomTypingBanks[payload.room_id].isTyping !== false) {
      const filtered = state.roomTypingBanks[payload.room_id].isTyping
        ? state.roomTypingBanks[payload.room_id].isTyping.filter(
            (e) => e === payload.user
          )
        : []
      if (filtered.length < 1) {
        data.push(payload.user)
      }
    } else {
      data.push(payload.user)
    }
    state.roomTypingBanks[payload.room_id].isTyping = data
  },
  DELETE_TYPING_BANKS_STATUS(state, payload) {
    const data = state.roomTypingBanks[payload.room_id].isTyping
      ? state.roomTypingBanks[payload.room_id].isTyping.filter(
          (e) => e !== payload.user
        )
      : []
    if (data.length < 1) {
      state.roomTypingBanks[payload.room_id].isTyping =
        Object.keys(state.roomTypingBanks).length < 2 ? false : []
    } else {
      state.roomTypingBanks[payload.room_id].isTyping =
        Object.keys(state.roomTypingBanks).length < 2 ? false : []
    }
  },
  REMOVE_USER_TYPING_ON_BANKS(state, payload) {
    if (state.roomTypingBanks[payload.room_id].isTyping !== false) {
      state.roomTypingBanks[payload.room_id].isTyping = state.roomTypingBanks[
        payload.room_id
      ].isTyping
        ? state.roomTypingBanks[payload.room_id].isTyping.filter(
            (e) => e !== payload.user
          )
        : []
    }
  },
  UPDATE_ROOM_NAME_ON_ROOM_LIST(state, payload) {
    const currentRoomLists = state.roomLists
    const index = _.findIndex(currentRoomLists, { id: payload.id })
    if (index > -1) {
      currentRoomLists[index].name = payload.text
      state.roomLists = currentRoomLists
    }
  },
  UPDATE_FILTER_CONVERSATION(state, payload) {
    state.roomFilter.conversation = payload
  },
  UPDATE_ROOM_TAGS(state, payload) {
    const currentRoomLists = state.roomLists
    const index = _.findIndex(currentRoomLists, { id: payload.id })
    if (index > -1) {
      currentRoomLists[index].tags.push(payload.tag)
      state.roomLists = currentRoomLists
    }
  },
  REMOVE_ROOM_TAGS(state, payload) {
    const currentRoomLists = state.roomLists
    const index = _.findIndex(currentRoomLists, { id: payload.id })
    if (index > -1) {
      const tagIndex = _.indexOf(currentRoomLists[index].tags, payload.tag)
      if (tagIndex > -1) {
        currentRoomLists[index].tags.splice(tagIndex, 1)
        state.roomLists = currentRoomLists
      }
    }
  },
  UPDATE_IS_LATEST(state, payload) {
    state.isLatest = payload > 0 ? state.isLatest + payload : payload
  },
  UPDATE_ROOM_STATUS_AFTER_AGENT_ACTION(state, payload) {
    const currentRoomLists = state.roomLists
    const index = _.findIndex(currentRoomLists, { id: payload.id })
    if (index > -1) {
      currentRoomLists[index].status = payload.status
      state.roomLists = currentRoomLists
    }
  },
  UPDATE_IS_FILTERING(state, payload) {
    state.isFiltering = payload
  },
  UPDATE_SEARCH_TYPE(state, payload) {
    state.searchRoomLists.type = payload
  },
  UPDATE_CONV_MODE(state, payload) {
    state.conversationMode = payload
  },
  UPDATE_ROOM_CHECK_LIST(state, payload) {
    if (payload.status) state.roomCheckList.push(payload)
    else
      state.roomCheckList = state.roomCheckList.filter((value) => {
        return value.id !== payload.id
      })
  },
  SET_ROOM_CHECK_LIST(state, payload) {
    state.roomCheckList = payload
  },
  SET_ROOM_CHECK_LIST_STATUS(state, payload) {
    const index = _.findIndex(state.roomCheckList, { id: payload.id })
    if (index < 0) return
    state.roomCheckList[index].progress = payload?.progress
  },
  PUSH_FILTER_PARAMS(state, payload) {
    state.filterParams[payload.variable].push(payload.value)
  },
  UPDATE_FILTER_PARAMS(state, payload) {
    if (payload.value === '') state.filterParams[payload.variable] = null
    else state.filterParams[payload.variable] = payload.value
  },
  RESET_FILTER_PARAMS(state, payload) {
    state.filterParams = {
      sessions: [],
      tags: [],
      user_ids: [],
      channels: [],
      untagged: null,
      response_status: [],
    }
  },
  UPDATE_EXPIRED_ROOM_PER_CHANNEL(state, payload) {
    const whatsappData =
      Object.keys(payload.data.data).filter((channel) => {
        return (
          channel === 'wa' ||
          channel === 'wa_cloud' ||
          channel === 'fb' ||
          channel === 'ig'
        )
      }) || []
    whatsappData.forEach((waType) => {
      state.expiredRoomCounts.data[waType] = payload.data.data[waType]
    })
    state.expiredRoomCounts.status = payload.status
  },
  UPDATE_IS_CONTACT_BLOCKED_ON_ROOM_LIST(state, payload) {
    const index = _.findIndex(state.roomLists, function (o) {
      return o.id === payload.roomId
    })
    const room = _.find(state.roomLists, function (o) {
      return o.id === payload.roomId
    })
    if (room) {
      room.is_blocked = payload.isBlocked
      room.status = payload.status
      state.roomLists.splice(index, 1, room) // remove 1 array at index and re-add element room
    }
  },
  REMOVE_ROOM_BY_ID(state, payload) {
    const index = _.findIndex(state.roomLists, { id: payload.id })
    if (index >= 0) state.roomLists.splice(index, 1)
  },
  UPDATE_NO_ROOM(state, payload) {
    state.noRoom = payload
  },
  UPDATE_INBOX_TABS(state, payload) {
    state.inboxTabs = payload
  },
  UPDATE_REOPEN_ROOM_LIST(state, payload) {
    const index = _.findIndex(state.roomLists, { id: payload.id })
    const currentRoom = state.roomLists[index]
    currentRoom.status = payload.status
    state.roomLists.splice(index, 1)
    state.roomLists.unshift(currentRoom)
  },
  UPDATE_IS_WA_CLOUD(state, payload) {
    const waOnPremise = _.filter(payload, { target_channel: 'wa' })
    const waCloud = _.filter(payload, { target_channel: 'wa_cloud' })
    if (waOnPremise.length) state.isWaCloud = false
    if (waCloud.length) state.isWaCloud = true
  },
  SET_UNREAD_COUNT(state, payload) {
    if (payload.type === 'comment') {
      state.unreadCount.comments = payload.data.info.total
    } else if (payload.type === 'customer_service') {
      // type message = customer_service from be
      state.unreadCount.messages = payload.data.info.total
    }
  },
  UPDATE_UNREAD_COUNT(state, payload) {
    if (payload.type === 'comment') {
      state.unreadCount.comments++
    } else if (payload.type === 'customer_service') {
      // type message = customer_service from be
      state.unreadCount.messages++
    }
  },
  DECREASE_UNREAD_COUNT(state, payload) {
    if (payload.type === 'comment') {
      state.unreadCount.comments = state.unreadCount.comments - payload.value
      if (state.unreadCount.comments < 0) state.unreadCount.comments = 0
    } else if (payload.type === 'customer_service') {
      // type message = customer_service from be
      state.unreadCount.messages = state.unreadCount.messages - payload.value
      if (state.unreadCount.messages < 0) state.unreadCount.messages = 0
    }
  },
  ADD_UNREAD_COUNT(state, payload) {
    if (payload.type === 'comment') {
      state.unreadCount.comments = state.unreadCount.comments + payload.value
    } else if (payload.type === 'customer_service') {
      // type message = customer_service from be
      state.unreadCount.messages = state.unreadCount.messages + payload.value
    }
  },
  SET_CHANNEL_LISTS(state, payload) {
    const submenus = payload
    let channelLists = _.filter(submenus, function (submenu) {
      return submenu.parent === 'integrations' && submenu.status === true
    })

    // manually add wa and ig_comment
    channelLists.push({
      title: 'WhatsApp',
      slug: 'whatsapp',
      status: true,
      parent: 'integrations',
      icon: 'WhatsApp',
      code: 'wa',
    })
    channelLists.push({
      title: 'Instagram comment',
      slug: 'ig_comment',
      status: true,
      parent: 'integrations',
      icon: 'chat',
      code: 'ig_comment',
    })

    // map id of each channel
    channelLists = channelLists.map((channel) => ({
      ...channel,
      id: `channel-integrations-${channel.code}`,
      name: channel.title,
      channel: channel.title,
      alias: channel.code,
      value: channel.code,
    }))

    state.channelLists = channelLists
  },
  UPDATE_SURVEY_FLOW(state, payload) {
    state.surveyFlow = payload || 'auto'
  },
  SET_ORGANIZATION_INBOX_POOLING(state, payload) {
    // delete later
    payload.thresholds.rooms_sla = 0.167
    payload.is_active.rooms_sla = true
    state.inboxPooling.defaultConfig = payload || null
  },
  SET_INBOX_POOLING_CHECKPOINT(state, payload) {
    state.inboxPooling.checkpoint = payload || new Date()
  },
  SET_INBOX_POOLING_TRIGGER(state, payload) {
    state.inboxPooling.triggerAt[payload.type] = payload.value
  },
  SET_INBOX_POOLING_CURSOR(state, payload) {
    state.inboxPooling.cursor = payload || null
  },
  UPDATE_ROOMS_BY_POOLING(state, payload) {
    state.roomLists = updateRoomPooling(payload, state.roomLists)
  },
  UPDATE_SLA_ON_ROOM(state, payload) {
    // Prepare the SLA data
    const slaData = {
      response: {},
      resolve: {},
      isShow: true,
    }

    // Calculate the time difference

    slaData.response.difference = timeDifference(
      payload.value.response_time_due,
      new Date()
    )
    slaData.resolve.difference = timeDifference(
      payload.value.resolved_time_due,
      new Date()
    )

    // Calculate the default config

    slaData.response.defaultConfig = timeDifference(
      payload.value.response_time_due,
      payload.value.participant_created_at
    )
    slaData.resolve.defaultConfig = timeDifference(
      payload.value.resolved_time_due,
      payload.value.participant_created_at
    )

    // Determine which SLA type will used
    let usedSLA = 'response'

    if (payload.value.response_time_due) {
      if (
        payload.value.response_time_due > payload.value.resolve_time_due ||
        !payload.value.resolve_time_due
      ) {
        usedSLA = 'response'
      } else {
        usedSLA = 'resolve'
      }
    } else if (payload.value.resolve_time_due) {
      if (
        payload.value.resolve_time_due > payload.value.response_time_due ||
        !payload.value.response_time_due
      ) {
        usedSLA = 'resolve'
      } else {
        usedSLA = 'response'
      }
    } else if (
      !payload.value.response_time_due &&
      !payload.value.resolve_time_due
    ) {
      usedSLA = 'response'
      slaData.isShow = false
    }

    let timeLeft = calculateToMinute(slaData[usedSLA].difference)

    if (timeLeft === 0 && slaData.response.difference.seconds < 0) {
      usedSLA = 'resolve'
      timeLeft = calculateToMinute(slaData.response.difference)
    }

    // Determine the variant of the time

    let variant = null

    const isWarning =
      calculateToMinute(slaData[usedSLA].defaultConfig) /
      (usedSLA === 'response' ? 2 : 3)

    if (timeLeft > 0 && timeLeft >= isWarning) {
      variant = null
      slaData.isShow = false
    } else if (timeLeft > 0 && timeLeft < isWarning) {
      variant = 'orange.50'
    } else if (
      timeLeft === 0 &&
      slaData[usedSLA].difference.seconds >= 0 &&
      timeLeft < isWarning
    ) {
      variant = 'orange.50'
    } else if (timeLeft === 0 && slaData[usedSLA].difference.seconds < 0) {
      variant = 'red.50'
    } else if (timeLeft < 0) {
      variant = 'red.50'
    }

    const tabVariant = variant

    const isBreached = slaData[usedSLA].difference.seconds < 0

    const slaTimeShow = {
      time: slaData[usedSLA].difference,
      variant: tabVariant,
      isShow: slaData.isShow,
      isBreached,
    }

    const currentRoomLists = state.roomLists
    const index = _.findIndex(currentRoomLists, { id: payload.value.room_id })
    if (index > -1) {
      // weird, but we need to update room name to trigger changes on loop
      currentRoomLists[index].name = currentRoomLists[index].name + ' '
      currentRoomLists[index].name = currentRoomLists[index].name + ''
      currentRoomLists[index].sla = slaTimeShow
      state.roomLists = currentRoomLists
    }
  },
  UPDATE_SLA_INBOX_STATUS(state, payload) {
    state.enable_inbox_sla = payload
  },
  UPDATE_ENABLE_GET_DIVISION_CHAT(state, payload) {
    state.enable_get_division_chat = payload
  },
  UPDATE_UNASSIGNED_COUNT(state, payload) {
    state.unassignedCount = payload
  },
}
