import { getField, updateField } from 'vuex-map-fields'
import { validTokenCookie } from '~/utils/general'
import insertOrganizationIdToUrl from '@/utils/insertOrganizationIdToUrl'
import { USER_AUTHENTICATION_URL } from '~/assets/variables/endpoints'

export const state = () => ({
  activeChannel: 'mqtt',
  isMqtt: false,
  mqttUsername: 'f74bdb07-624b-4194-a6cd-1e66ecac4106',
  mqttCredential: null,
  isMqttPrimary: true,
})

export const getters = {
  getField,
  getMqttCredentials(state) {
    return state.mqttCredential
  },
  getMqttUsername(state) {
    return state.mqttUsername
  },
}

export const actions = {
  getMqttCredentials({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      if (localStorage.getItem('qontak_mqtt_cred')) {
        const qontakMqttCred = JSON.parse(
          localStorage.getItem('qontak_mqtt_cred')
        )
        resolve(qontakMqttCred)
      } else {
        try {
          const additional = {
            headers: {
              Authorization: validTokenCookie(),
            },
          }
          this.$axios
            .get(
              `${insertOrganizationIdToUrl(
                USER_AUTHENTICATION_URL,
                this.$auth.user.organization_id
              )}/mqtt_credentials`,
              additional
            )
            .then((res) => {
              if (res) {
                localStorage.setItem(
                  'qontak_mqtt_cred',
                  JSON.stringify(res.data.data)
                )
                commit('SET_MQTT_CREDENTIAL', res.data.data)
                resolve(res.data.data)
              } else {
                reject(res)
              }
            })
            .catch((err) => {
              reject(err)
            })
        } catch (error) {
          console.error('error: ', error)
        }
      }
    })
  },
}

export const mutations = {
  updateField,
  SET_MQTT_CREDENTIAL(state, payload) {
    state.mqttCredential = payload
  },
}
